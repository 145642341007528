import * as React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const ThumbUpSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  accessibilityLabel,
  testID,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    accessibilityLabel={accessibilityLabel}
    testID={testID}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.877 43.9378H35.603C38.4978 43.9378 40.9833 41.8684 41.5024 39.0093L43.898 25.8433C44.2274 24.0839 43.7483 22.2944 42.6104 20.9248C41.4624 19.5553 39.7954 18.7655 38.0087 18.7655H30.0031C31.0013 17.036 32.2391 13.927 32.2391 9.21841C32.2391 6.43926 29.8035 4 27.0285 4C24.1537 4 21.8179 6.33929 21.8179 9.21841C21.8179 13.717 20.0711 17.9457 16.8968 21.1248L15.7957 22.2275C15.3166 20.8356 13.9969 19.8352 12.4448 19.8352H7.5436C5.58713 19.8352 4 21.4247 4 23.3841V40.4489C4 42.4083 5.58713 43.9978 7.5436 43.9978H12.4348C14.3912 43.9978 15.9784 42.4083 15.9884 40.4489V24.8239C16.0449 24.7869 16.0985 24.7435 16.1481 24.6937L18.3043 22.5343C21.8578 18.9754 23.8143 14.2469 23.8143 9.21841C23.8143 7.44895 25.2517 5.99939 27.0285 5.99939C28.8352 5.99939 30.2427 7.72886 30.2427 9.21841C30.2427 15.8193 27.5761 18.8406 27.3882 19.0535C27.382 19.0605 27.3785 19.0644 27.3779 19.0654C27.0884 19.3553 26.9986 19.7852 27.1583 20.1551C27.308 20.535 27.6773 20.7749 28.0766 20.7749H38.0087C39.1965 20.7749 40.3145 21.3047 41.0731 22.2144C41.8318 23.1242 42.1412 24.3238 41.9316 25.4934L39.5459 38.6594C39.1965 40.5588 37.5395 41.9384 35.613 41.9384H27.887C25.4513 41.9384 23.0657 41.5186 20.7798 40.6988C20.2607 40.5089 19.6917 40.7788 19.5021 41.2986C19.3124 41.8185 19.5819 42.3883 20.101 42.5782C22.5965 43.478 25.2118 43.9378 27.877 43.9378ZM5.9964 23.3841C5.9964 22.5344 6.68515 21.8346 7.5436 21.8346H12.4348C13.2932 21.8346 13.982 22.5344 13.982 23.3841V40.4489C13.982 41.3086 13.2932 41.9984 12.4448 41.9984H7.5436C6.68515 41.9984 5.9964 41.3086 5.9964 40.4489V23.3841Z"
      fill={color}
    />
  </AccessibleSvg>
)

export const ThumbUp = styled(ThumbUpSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.standard,
}))``
