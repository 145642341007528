import * as React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const QuoteSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  accessibilityLabel,
  testID,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    accessibilityLabel={accessibilityLabel}
    testID={testID}>
    <Path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5756 9.50882C18.4366 9.72407 22.3134 13.8516 22.3134 18.9118C22.3134 24.1097 18.2226 28.3235 13.1764 28.3235C9.81167 28.3235 6.87174 26.4501 5.28606 23.6606C5.3393 27.2057 6.31866 30.2397 8.11054 32.5073C10.3502 35.3417 13.9919 37.1471 19.1456 37.1471C19.7764 37.1471 20.2877 37.6738 20.2877 38.3235C20.2877 38.9733 19.7764 39.5 19.1456 39.5C13.4492 39.5 9.09605 37.4818 6.33896 33.9927C3.59721 30.5229 2.56673 25.7667 3.16374 20.539C3.45946 17.9496 4.19257 15.9514 5.14753 14.4155C6.69637 11.4871 9.71109 9.5 13.1764 9.5C13.2336 9.5 13.2907 9.50054 13.3476 9.50162C13.3637 9.50112 13.3778 9.50077 13.3897 9.50053L13.4182 9.50009L13.4281 9.50002L13.4319 9.5L13.4336 9.5C13.4337 9.5 13.4338 9.5 13.4339 9.50367C13.4343 9.50368 13.4346 9.50369 13.435 9.5037V9.5C13.4826 9.5 13.5295 9.503 13.5756 9.50882ZM35.8631 28.3235C40.9093 28.3235 45.0001 24.1097 45.0001 18.9118C45.0001 13.8514 41.1231 9.72385 36.2619 9.5088C36.2159 9.50299 36.169 9.5 36.1215 9.5V9.50369L36.1204 9.50366C36.1203 9.5 36.1202 9.5 36.1201 9.5L36.1184 9.5L36.1146 9.50002L36.1047 9.50009L36.0762 9.50053L36.0342 9.50162C35.9773 9.50054 35.9203 9.5 35.8631 9.5C32.3989 9.5 29.385 11.4859 27.8357 14.4128C26.8799 15.949 26.1461 17.948 25.8502 20.539C25.2532 25.7667 26.2837 30.5229 29.0255 33.9927C31.7825 37.4818 36.1357 39.5 41.8321 39.5C42.4629 39.5 42.9742 38.9733 42.9742 38.3235C42.9742 37.6738 42.4629 37.1471 41.8321 37.1471C36.6784 37.1471 33.0367 35.3417 30.797 32.5073C29.0051 30.2396 28.0257 27.2054 27.9725 23.6602C29.5582 26.4499 32.4982 28.3235 35.8631 28.3235Z"
    />
  </AccessibleSvg>
)

export const Quote = styled(QuoteSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.standard,
}))``
